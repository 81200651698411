export default [
  {
    id: 1,
    text: "about",
    path: "/about",
  },
  {
    id: 2,
    text: "projects",
    path: "/projects",
  },
  {
    id: 3,
    text: "contact",
    path: "/contact",
  },
]
