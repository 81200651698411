import React from "react"
import { FiMail, FiGithub, FiLinkedin } from "react-icons/fi"

export default [
  {
    id: 1,
    name: "email",
    path: "mailto:alexhcurtin@gmail.com",
    icon: <FiMail />,
  },
  {
    id: 2,
    name: "github",
    path: "https://github.com/alex-curtin",
    icon: <FiGithub />,
  },
  {
    id: 3,
    name: "linkedin",
    path: "https://www.linkedin.com/in/alexander-curtin/",
    icon: <FiLinkedin />,
  },
]
