import { createGlobalStyle } from "styled-components"
import { setColor, setFont, setFlex } from "./styles"
import bg from "../images/felt.png"

const GlobalStyles = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    ${setFont.main};
    color: ${setColor.darkGrey};
    font-size:100%;
    background: #1dffc2;
    background-image: ${setColor.overlayPrimay}, url(${bg});
  }
  
  main {
    background-size: cover;
  }

  h1 {
    font-size: 2.5em;
    margin: 0;
    font-weight: 300;
    ${setFont.display}
  }

  h2 {
    font-size: 1.75em;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  h3 {
    font-size: 1.5em;
    margin-bottom: 0.5rem;
  }

  h4 {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
  }

  h5 {
    font-size: 1em;
    margin-bottom: 1.5em;
    font-weight: bold;
  }

  h6 {
    font-size: 1em;
    font-weight: bold;
  }

  p {
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    color: ${setColor.mainBlack};
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 100vh;
    width: 100vw;
  }
`

export default GlobalStyles
