import React from "react"
import styled from "styled-components"
import { setFlex, setRem, media } from "../styles/styles"
import links from "../constants/links"

const Footer = () => {
  return (
    <FooterWrapper>
      <div>Alex Curtin © {new Date().getFullYear()}</div>

      <ul className="nav mx-3">
        {links.map(link => (
          <li className="nav-item" key={link.id}>
            <a href={link.path} className="nav-link">
              {link.icon}
            </a>
          </li>
        ))}
      </ul>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  ${setFlex({ x: "space-around" })};
  width: 100%;
  padding: ${setRem(8)} 20%;
  ul {
    list-style: none;
    ${setFlex({ x: "space-around" })};
    width: 20%;
  }
  ${media.phone`
    padding: ${setRem(8)} 0;
    svg {
      margin: 0 ${setRem(8)};
    }
  `}
`

export default Footer
