import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FiMenu, FiX } from "react-icons/fi"
import {
  setRem,
  setFlex,
  setLetterSpacing,
  setColor,
  media,
} from "../styles/styles"
import navItems from "../constants/nav-items"

const Navbar = ({ siteTitle }) => {
  const [showNav, setShowNav] = useState(false)

  return (
    <NavWrapper showNav={showNav}>
      <Link to="/">
        <h1>AC</h1>
      </Link>
      <button className="burger" onClick={() => setShowNav(true)}>
        <FiMenu />
      </button>
      <ul>
        <li>
          <button className="close" onClick={() => setShowNav(false)}>
            <FiX />
          </button>
        </li>
        {navItems.map(link => (
          <li key={link.id}>
            <Link to={link.path} activeClassName="active">
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
    </NavWrapper>
  )
}

const NavWrapper = styled.nav`
  margin: ${setRem()};
  ${setFlex({ x: "space-between", y: "center" })};
  padding: ${setRem(8)} ${setRem(32)};
  h1 {
    color: ${setColor.darkGrey};
    border: 2px solid ${setColor.darkGrey};
    padding: ${setRem(24)} ${setRem(4)} 0 ${setRem(14)};
    border-radius: 2px;
    line-height: 2.5rem;

    &:hover {
      transform: rotate(-2deg) scale(1.05);
    }
  }
  ul {
    list-style: none;
    margin-left: ${setRem(24)};
  }
  li {
    margin: ${setRem()};
    font-weight: 400;
    font-size: ${setRem(18)};
    ${setLetterSpacing()};
    padding-bottom: ${setRem(6)};
    display: inline;
    position: relative;
    &:hover {
      a::after {
        width: 100%;
      }
    }
  }
  a {
    color: ${setColor.darkGrey};
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 0;
      height: ${setRem(3)};
      background: ${setColor.primaryDark};
      transition: all 0.3s ease-in-out;
    }
  }
  .active {
    &::after {
      width: 100%;
    }
  }

  .burger,
  .close {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    color: ${setColor.darkGrey};
    display: none;
  }
  .burger {
    font-size: ${setRem(64)};
  }
  .close {
    font-size: ${setRem(28)};
  }

  ${media.phone`
    margin: ${setRem(8)};
    padding: ${setRem(8)} ${setRem(8)};

    .burger, .close {
      display: inline-block;
    }

    ul {
      display: flex;
      width: ${props => (props.showNav ? "50%" : "0")};
      flex-direction: column;
      align-items: flex-start;
      background: ${setColor.primaryMuted};
      overflow: hidden;
      height: 100%;
      z-index: 50;
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      transition: width 0.5s ease-in-out;
    }
  `}
`

export default Navbar
